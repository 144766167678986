import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./navbar.scss";

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);
  return (
    <div className="z-30 Navbar">  
      <a href="/#inventu-research">
        <StaticImage
          id="image-logo"
          className="max-h-24 md:ml-12 "
          src={"../../images/r-logo.png"}
          alt="logo"
          height={123}
        ></StaticImage>
      </a>
      <button
        onClick={() => setShowLinks(!showLinks)}
        id="icon"
        className="md:hidden"
      >
        <i class="fa fa-bars fa-4x"></i>
      </button>
      <ul className="hidden grid-cols-4 gap-8 mr-12 md:grid">
        <li className="col-span-1 text-center">
          <a class="nav-li" href="/#get-to-know-us">
            <h3 className="">ABOUT</h3>
          </a>
        </li>
        <li className="col-span-1 text-center">
          <a class="nav-li" href="/#slider-section">
            <h3 className="">PRODUCTS</h3>
          </a>
        </li>
        <li className="col-span-1 text-center">
          <a class="nav-li" href="/#let-us-help">
            <h3 className="">SERVICES</h3>
          </a>
        </li>
        <li className="col-span-1 text-center">
          <a class="nav-li" href="/#contact">
            <h3 className="">CONTACT</h3>
          </a>
        </li>
      </ul>
      <ul
        class="dropdown"
        id={showLinks ? "" : "hidden"}
        className="pt-18 md:hidden"
      >
        <li className="text-center">
          <a class="nav-li" href="/#get-to-know-us">
            <h3 className="">ABOUT</h3>
          </a>
        </li>
        <li className="text-center">
          <a class="nav-li" href="/#slider-section">
            <h3 className="">PRODUCTS</h3>
          </a>
        </li>
        <li className="text-center">
          <a class="nav-li" href="/#let-us-help">
            <h3 className="">SERVICES</h3>
          </a>
        </li>
        <li className="text-center">
          <a class="nav-li" href="/#contact">
            <h3 className="">CONTACT</h3>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
