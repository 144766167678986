import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";

import Navbar from "@components/navbar/Navbar";
import CustomContactForm from "@components/contactForm/CustomContactForm";

const IndexPage = () => {
  const { headingBackground, ergoBackground } = useStaticQuery(
    graphql`
      query {
        headingBackground: file(
          relativePath: { eq: "images/front-page-image.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ergoBackground: file(
          relativePath: { eq: "images/Ergonomyx_Newroom.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const headingBackgroundFluid = headingBackground.childImageSharp.fluid;
  const ergoBackgroundFluid = ergoBackground.childImageSharp.fluid;
  return [
    <head>
      <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link rel="icon" 
      type="image/png"
      href="../../static/logo-black.png"></link>
    </head>,
    <div className="relative">
      <Helmet>
        <title>Inventu Research</title>
        <meta name="icon" href="../../static/logo-black.png"/>
        <meta 
          name="description"
          content="At Inventu, we are dedicated to developing and improving products for both commercial and personal applicants."
        />
      </Helmet>
      <Navbar />
      <section id="inventu-research" className="relative">
        <BackgroundImage
          Tag="section"
          className={"absolute z-0 h-screen"}
          fluid={headingBackgroundFluid}
        >
          <div id="gradient" className="z-10">
            <div id="title-container" className="container z-20">
              <h1 id="inventu-header" className="text-center text-white">
                INVENTU
                <br />
                RESEARCH
              </h1>
              <h2
                id="create-develop-inspire"
                className="mt-40 text-center text-white"
              >
                Create, Develop, Inspire
              </h2>
            </div>
          </div>
        </BackgroundImage>
      </section>

      <section id="get-to-know-us">
        <div className="container">
          <div className="grid h-auto min-h-screen grid-cols-10">
            <div
              id="images-map-container"
              className="md:col-span-4 p-36 2xl:py-56 px-36"
            >
              <div className="flex flex-col justify-center h-full">
                <div className="">
                  <StaticImage
                    id="image-canada"
                    src={"../images/canada.png"}
                    alt="canada"
                    width={1015}
                    height={844}
                    placeholder="tracedSVG"
                  ></StaticImage>
                </div>
                <div className="mt-32">
                  <StaticImage
                    id="image-usa"
                    src={"../images/usa.png"}
                    alt="usa"
                    width={925}
                    height={661}
                    placeholder="tracedSVG"
                  ></StaticImage>
                </div>
              </div>
            </div>

            <div className="col-span-10 px-36 sm:col-span-8 md:col-span-6">
              <div className="flex flex-col justify-center h-full">
                <h2 id="get-to-know-header">Get to Know Us</h2>
                <p id="get-to-know-body" className="mt-40 lg:mr-40">
                  We are a technology company headquartered in Victoria, British
                  Columbia. Founded and incorporated in the year of 2012, we are
                  a private corporation specializing in product development and
                  patent licensing for both commercial and personal
                  applications. Today, we hold over a dozen patents under our
                  name and we operate within Canada and the US.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="our-mission" className="flex flex-col justify-center">
        <div className="container">
          <div className="grid grid-cols-10">
            <div className="col-span-10 px-40 sm:col-span-8 md:col-span-6">
              <h2 id="our-mission-header" className="text-white">
                Our Mission
              </h2>
              <p id="our-mission-body" className="mt-40 text-white lg:mr-40">
                At Inventu Research, we devote ourselves to the research,
                development, and improvement of our products through the
                knowledge and expertise of our culturally-diverse team. Moving
                forward, we wish to continue this focus while continuing to
                expand upon our product portfolio and market reach through our
                continued expansion efforts into the European and Global
                markets.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="slider-section">
        <BackgroundImage
          Tag="section"
          className={"absolute z-0 min-h-screen h-auto"}
          fluid={ergoBackgroundFluid}
        >
          <div className="z-10">
            <div id="icon-container">
              <StaticImage
                id="image-ergo-icon"
                src={"../images/ergonomyx-icon.png"}
                alt="patent"
                height={300}
                placeholder="none"
              ></StaticImage>
            </div>
            <div id="logo-container">
              <StaticImage
                id="image-ergo-logo"
                src={"../images/ergonomyx-logo.png"}
                alt="patent"
                height={280}
                placeholder="none"
              ></StaticImage>
            </div>
            <div
              id="journey-header-container"
              className="z-30 h-full min-h-screen "
            >
              <div className="flex flex-col justify-center h-screen p-20 px-32 pt-40 pb-32">
                <div className="inline-flex">
                  <h1 id="journey-header">
                    Our Journey
                    <br /> is one of <br />
                    <span style={{ color: "#ffae42" }}>Innovation</span>
                  </h1>
                </div>
                <div className="inline-flex">
                  <span className="relative">
                    <h2 id="explore-header" className="mt-32 mb-24">
                      Explore our diverse <br />
                      product library
                    </h2>
                    <div id="visit-btn-container" className="absolute bottom-0">
                      <button class="btn bg-black py-4 px-16 rounded-full">
                        <a
                          href="https://www.ergonomyx.com/"
                          class="visit-button"
                          className="inline"
                        >
                          <h3 className="justify-start inline">Visit</h3>
                        </a>
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>

      <section id="let-us-help" className="">
        <div className="flex flex-col justify-center h-auto min-h-screen">
          <h2
            id="let-us-help-header"
            className="mx-10 text-center text-white mt-28"
          >
            Let Us Help You Spark Real Change
          </h2>
          <h3 id="our-offerings-header" className="text-white ml-28 mt-28">
            Explore our Offerings:
          </h3>
          <div className="container mb-20 sm:mb-0">
            <div className="flex flex-col items-center justify-evenly sm:flex-row gap-y-16 sm:gap-y-0">
              <div
                className="flex flex-col min-h-full sm:self-end m-18"
                id="product-development"
              >
                <StaticImage
                  className="max-w-sm m-16 sm:max-w-xs"
                  id="image-product"
                  src={"../images/product-development.png"}
                  alt="product"
                  width={530}
                  placeholder="tracedSVG"
                ></StaticImage>
                <h3 id="services-offered" className="text-center text-white">
                  Product Development
                </h3>
              </div>
              <div className="flex flex-col sm:self-end min-h-fullm-18">
                <StaticImage
                  className="max-w-md m-16 sm:max-w-xs"
                  id="image-patent"
                  src={"../images/patent-licensing.png"}
                  alt="patent"
                  width={538}
                  placeholder="tracedSVG"
                ></StaticImage>
                <h3 id="services-offered" className="text-center text-white">
                  Patent Licensing
                </h3>
              </div>
              <div className="flex flex-col min-h-full mt-10 sm:self-end m-18 sm:mt-0">
                <StaticImage
                  className="object-contain max-w-md max-h-72 sm:max-w-xs"
                  id="image-ip"
                  src={"../images/ip-protection.png"}
                  alt="ip"
                  width={330}
                  objectFit="contain"
                  placeholder="tracedSVG"
                ></StaticImage>
                <h3
                  class="services-offered"
                  className="mt-10 text-center text-white"
                >
                  IP Protection
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="our-partners" className="pt-64">
        <h2 id="our-partners-header" className="text-center">
          OUR PARTNERS
        </h2>
        <div
          id="logos-container"
          className="flex flex-row flex-wrap p-40 justify-evenly"
        >
          <StaticImage
            id="image-nserc"
            src={"../images/nserc-crsng.png"}
            alt="nserc"
            width={300}
            placeholder="tracedSVG"
          ></StaticImage>
          <StaticImage
            id="image-nrc"
            src={"../images/nrc.png"}
            alt="nrc"
            width={300}
            placeholder="tracedSVG"
          ></StaticImage>
          <StaticImage
            id="image-ergo-logo"
            src={"../images/ergonomyx-logo-clear.png"}
            alt="ergo logo"
            width={270}
            placeholder="tracedSVG"
          ></StaticImage>
          <StaticImage
            id="image-borealis"
            src={"../images/borealis.png"}
            alt="borealis"
            width={230}
            placeholder="tracedSVG"
          ></StaticImage>
          <StaticImage
            id="image-salyx"
            src={"../images/salyx.png"}
            alt="salyx logo"
            width={300}
            placeholder="tracedSVG"
          ></StaticImage>
        </div>
      </section>
      <section id="contact" className="pt-64">
        <div>
          <CustomContactForm />
          {/* <div
            id="contact-form-container"
            className="flex flex-col flex-wrap mx-40 mt-32"
          >
            <CustomContactForm />
          </div> */}
          <div className="grid grid-cols-2 mx-10 md:mx-0 md:grid-cols-4">
            <div
              id="image-map-container"
              className="hidden col-span-2 p-32 md:block"
            >
              <StaticImage
                id="image-map"
                src={"../images/google-maps-location.png"}
                alt="map"
                width={1477}
                height={706}
                placeholder="none"
              ></StaticImage>
            </div>
            <div className="py-32 md:col-span-1">
              <h3 id="address-header">Address</h3>
              <p id="address-body">
                3948 Quadra St, Suite 3, Victoria BC, V8X 1J6
              </p>
              <h3 id="phone-header">Phone (Toll free):</h3>
              <p id="phone-body">1 (833) 746-6669 Fax (844) 687-6837</p>
              <h3 id="fax-header">Fax:</h3>
              <p id="phone-body">1 (844) 687-6837</p>
            </div>
            <div className="col-span-1 my-32">
              <h3 id="address-header">Email</h3>
              <p id="address-body">info@inventuresearch.com</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  ];
};

export default IndexPage;
