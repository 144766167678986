import React from "react";
import { InlineWidget } from "react-calendly";
import submitHubspotForm from "@services/Hubspot.js";

import "./customContactForm.scss";

const calendlyPageSettings = {
  backgroundColor: "F5F5F5",
  hideEventTypeDetails: true,
  hideLandingPageDetails: true,
  primaryColor: "00a2ff",
  textColor: "4d5055",
};
export default class CustomContactForm extends React.Component {
  state = {
    name: "",
    phone: "",
    email: "",
    website: "",
    message: "",

    name_error: "",
    phone_error: "",
    email_error: "",
    website_error: "",
    message_error: "",

    showForm: true,
    bookingType: "30min",
    finishedBooking: false,
  };

  componentDidMount = () => {
    const self = this;

    function isCalendlyEvent(e) {
      return e.data.event && e.data.event.indexOf("calendly") === 0;
    }

    window.addEventListener("message", function (e) {
      if (isCalendlyEvent(e)) {
        console.log(e.data);
        if (e.data.event === "calendly.event_scheduled") {
          console.log("calendly.event_scheduled");
          // send all the info as an email to Fabian
          // change the screen to a finisehd screen

          self.setState({
            finishedBooking: true,
          });
        }
      }
    });
  };

  onChangeName = (e) => {
    // no spaces or special chars
    console.log(e.target.value);
    this.setState({
      name: e.target.value,
      name_error: "",
    });
  };

  onChangePhone = (e) => {
    // no letters or special chars
    console.log(e.target.value);
    this.setState({
      phone: e.target.value,
      phone_error: "",
    });
  };

  onChangeEmail = (e) => {
    // all permitted
    console.log(e.target.value);
    this.setState({
      email: e.target.value,
      email_error: "",
    });
  };

  onChangeWebsite = (e) => {
    // all permitted
    console.log(e.target.value);
    this.setState({
      website: e.target.value,
      website_error: "",
    });
  };

  onChangeMessage = (e) => {
    // chat limit
    console.log(e.target.value);
    this.setState({
      message: e.target.value,
      message_error: "",
    });
  };

  onSubmit = async () => {
    await submitHubspotForm(this.state).then((res) => {
      console.log("data:", res);
      if (res.status === 200) {
        this.setState({
          showForm: false,
        });
      } else {
        this.parseErrors(res.data.errors);
      }
      return;
    });
  };

  updateBookingType = (e) => {
    this.setState({
      bookingType: e.target.name,
    });
  };

  showForm = () => {
    if (this.state.showForm) {
      return (
        <div>
          <h2
            id="contact-us-header"
            className="font-normal text-center text-black"
          >
            Register and Schedule a Meeting
          </h2>
          <form
            id="contactForm"
            className={
              "grid grid-cols-1 md:grid-cols-2 gap-x-6 " +
              (this.state.showForm ? " show" : " hide")
            }
          >
            <div className="flex flex-col">
              <input
                className={"input " + (this.state.name_error ? " error" : "")}
                name="name"
                type="text"
                value={this.state.name}
                placeholder="Your Name"
                onChange={this.onChangeName}
              />
              <p className="text-red-500 error-message">
                {this.state.name_error}
              </p>
              <input
                className={"input " + (this.state.phone_error ? " error" : "")}
                name="phone"
                type="text"
                value={this.state.phone}
                placeholder="Phone #"
                onChange={this.onChangePhone}
              />
              <p className="text-red-500 error-message">
                {this.state.phone_error}
              </p>
              <input
                className={"input " + (this.state.email_error ? " error" : "")}
                name="email"
                type="text"
                value={this.state.email}
                placeholder="Email Address"
                onChange={this.onChangeEmail}
              />
              <p className="text-red-500 error-message">
                {this.state.email_error}
              </p>
              <input
                className={
                  "input " + (this.state.website_error ? " error" : "")
                }
                name="website"
                type="text"
                value={this.state.website}
                placeholder="Website Link (optional)"
                onChange={this.onChangeWebsite}
              />
              <p className="text-red-500 error-message">
                {this.state.website_error}
              </p>
            </div>
            <div className="flex flex-col h-full">
              <textarea
                className={
                  "input input-multi-line " +
                  (this.state.message_error ? " error" : "")
                }
                name="message"
                type="text"
                value={this.state.message}
                placeholder="Message..."
                onChange={this.onChangeMessage}
              />
              <p className="text-red-500 error-message">
                {this.state.message_error}
              </p>
              <div className="flex justify-center">
                <button
                  id="submit-btn"
                  type="button"
                  className="font-medium text-white bg-black"
                  onClick={() => this.onSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (this.state.finishedBooking) {
      return (
        <div
          id="completion-screen"
          className="container justify-center md:flex"
        >
          <div className="justify-center text-center md:text-left md:flex md:flex-col">
            <h2 className="text-center">Thanks for booking </h2>
            <p className="text-center">We'll speak with you soon!</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container justify-center md:flex">
          <div
            id="contact-finish"
            className="mt-4 text-center md:flex-grow md:text-left md:flex md:flex-col"
          >
            <div className="justify-center md:justify-left flex-block">
              <h2 className="flex-block md:flex">
                Thanks for <br />
                reaching out
              </h2>
              <p className="my-4 flex-block md:flex">
                Would you like to book a meeting as well?
              </p>
            </div>
            <div className="mb-8 flex-block md:flex justiy-center md:justify-left meeting-btns">
              <button
                id="submit-btn"
                type="button"
                name="30min"
                className={
                  "option-btn px-5 py-2 mt-2 font-medium text-black bg-white rounded-2xl " +
                  (this.state.bookingType && this.state.bookingType !== "30min"
                    ? "notSelected"
                    : "")
                }
                onClick={this.updateBookingType}
              >
                30 mins
              </button>
            </div>
          </div>
          <div id="calendlyForm" className="flex-grow">
            <InlineWidget
              url={process.env.CALENDLY + this.state.bookingType}
              prefill={{
                email: this.state.email,
                name: this.state.name,
                customAnswers: {
                  a1: this.state.message,
                },
              }}
              pageSettings={calendlyPageSettings}
            />
          </div>
        </div>
      );
    }
  };

  parseErrors = (errors) => {
    errors.forEach((error) => {
      let sentences = error.message.split(". ", 2);
      if (sentences[0].includes("fields.firstname")) {
        this.setState({
          name_error: sentences[1],
        });
      } else if (sentences[0].includes("fields.phone")) {
        this.setState({
          phone_error: sentences[1],
        });
      } else if (sentences[0].includes("fields.email")) {
        this.setState({
          email_error: sentences[1],
        });
      } else if (sentences[0].includes("fields.website")) {
        this.setState({
          website_error: sentences[1],
        });
      } else if (sentences[0].includes("fields.message")) {
        this.setState({
          message_error: sentences[1],
        });
      }
    });
    console.log(this.state);
  };

  render() {
    return (
      <div id="customContactForm" className="container">
        {this.showForm()}
      </div>
    );
  }
}
