import axios from "axios";

export const submitHubspotForm = async (responseState) => {
  console.log(responseState);
  const portalId = process.env.PORTAL_ID;
  const formGuid = process.env.FORM_GUID;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: "firstname",
            value: responseState.name,
          },
          {
            name: "phone",
            value: responseState.phone,
          },
          {
            name: "email",
            value: responseState.email,
          },
          {
            name: "website",
            value: responseState.website,
          },
          {
            name: "message",
            value: responseState.message,
          },
        ],
      },
      config
    );
    return response;
  } catch (err) {
    console.log('err:', err.response);
    return err.response;
  }
};

export default submitHubspotForm;
